<template>
    <div style="width: 33.3%;">
        <div class="list-item" v-if="recordItem">
            <div :class="recordItem.state<3?'warning':'warningEnd'">
                <el-steps :active="recordItem.state">
                    <el-step v-for="(ite,idx) in warningStatusDic" :key="idx">
                        <img src="https://hichar-file.oss-cn-shanghai.aliyuncs.com/app/devops/menu-warning.png" 
                            slot="icon" style="width:18px;height:18px" alt="" v-if="recordItem.state==1 && recordItem.state==ite.value">
                        <img src="../../../assets/icon/wait-icon.png" slot="icon" style="width:15px;height:15px" alt="" v-else-if="recordItem.state==2 && recordItem.state==ite.value">
                        <i slot="icon" class="el-icon-success a-fs-18 a-c-blue" v-else-if="recordItem.state==3 && recordItem.state==ite.value"></i>
                        <div class="step-spot1" slot="icon" v-else-if="recordItem.state>idx"></div>
                        <div class="step-spot" slot="icon" v-else></div>
                        <div slot="title">
                            <div class="a-fs-14" :class="recordItem.state==ite.value && recordItem.state==1?'a-c-error':''">{{ite.label}}</div>
                        </div>
                    </el-step>
                </el-steps>
                <div>
                    <div class="a-flex-rfsc list-item-title">
                        <span class="">{{ recordItem.alertName || '-' }}</span>
                        <el-tag class='a-ml-10' type="danger" v-if="recordItem.misreport == 1">误报</el-tag>
                    </div>
                    <div class="a-flex-rfsfs list-item-info">
                        <span class="list-item-infoTitle">预警时间:</span>
                        <span class="list-item-infoVal">{{ recordItem.createTime || '-' }}</span>
                    </div>
                    <!-- 站点设备在线率 -->
                    <div v-if="recordItem.alertRuleId == 1"> 
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">设备数:</span>
                            <span class="list-item-infoVal" v-if="recordItem.otherJson">{{ recordItem.otherJson.current || 0 }}/{{ recordItem.otherJson.total || 0 }}（在线数/总数）</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info" v-if="recordItem.otherJson && recordItem.state==3">
                            <span class="list-item-infoTitle">恢复值:</span>
                            <span class="list-item-infoVal">{{ recordItem.otherJson.recoverCurrent || 0 }}/{{ recordItem.otherJson.recoverTotal || 0 }}（在线数/总数）</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">预警说明:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.alertDesc || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">站点名称:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.stationName || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">站点地址:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.stationAddress || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">所属商户:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.companyName || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">商户负责人:</span>
                            <span class="list-item-infoVal">{{ recordItem.compnayUserName || '-' }} {{ recordItem.companyUserPhone || '-' }}</span>
                        </div>
                    </div>
                    <!-- 无效订单指标预警 -->
                    <div  v-if="recordItem.alertRuleId == 2">
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">订单数:</span>
                            <span class="list-item-infoVal" v-if="recordItem.otherJson">{{ recordItem.otherJson.current || 0 }}/{{ recordItem.otherJson.total || 0 }}（无效订单/总订单）</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info" v-if="recordItem.otherJson && recordItem.state==3">
                            <span class="list-item-infoTitle">恢复值:</span>
                            <span class="list-item-infoVal">{{ recordItem.otherJson.recoverCurrent || 0 }}/{{ recordItem.otherJson.recoverTotal || 0 }}（无效订单/总订单）</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">预警说明:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.alertDesc || '-' }}</span>
                        </div>
                    </div>
                    <!-- 单台设备失败订单预警 -->
                    <div  v-if="recordItem.alertRuleId == 3">
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">设备号:</span>
                            <span class="list-item-infoVal">{{ recordItem.deviceCode || '-' }}</span>
                            <span class="list-item-infoVal" v-if="recordItem.otherJson">（当前值：{{ recordItem.otherJson.current || 0 }}）</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">设备名称:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.mark || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info" v-if="recordItem.otherJson && recordItem.state==3">
                            <span class="list-item-infoTitle">恢复值:</span>
                            <span class="list-item-infoVal">{{ recordItem.otherJson.recoverCurrent || 0 }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">预警说明:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.alertDesc || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">站点名称:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.stationName || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">站点地址:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.stationAddress || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">所属商户:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.companyName || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">商户负责人:</span>
                            <span class="list-item-infoVal">{{ recordItem.compnayUserName || '-' }} {{ recordItem.companyUserPhone || '-' }}</span>
                        </div>
                    </div>
                    <!-- 订单指标数预警 -->
                    <div  v-if="recordItem.alertRuleId == 4">
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">订单数:</span>
                            <span class="list-item-infoVal" v-if="recordItem.otherJson">{{ recordItem.otherJson.current || 0 }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info" v-if="recordItem.otherJson && recordItem.state==3">
                            <span class="list-item-infoTitle">恢复值:</span>
                            <span class="list-item-infoVal">{{ recordItem.otherJson.recoverCurrent || 0 }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">预警说明:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.alertDesc || '-' }}</span>
                        </div>
                    </div>
                    <!-- 采购单执行失败预警 -->
                    <div  v-if="recordItem.alertRuleId == 5">
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">预警说明:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.alertDesc || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">采购ID:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.orderId || '-' }}</span>
                        </div>
                    </div>
                    <!-- 采购设备合格率预警 -->
                    <div  v-if="recordItem.alertRuleId == 6">
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">当前合格率:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.otherJson.currentRatio || '0%' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">预警说明:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.alertDesc || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">采购ID:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.orderId || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">所属商户:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.companyName || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">商户负责人:</span>
                            <span class="list-item-infoVal">{{ recordItem.compnayUserName || '-' }} {{ recordItem.companyUserPhone || '-' }}</span>
                        </div>
                    </div>
                    <!-- 支付结单超时预警预警 -->
                    <div  v-if="recordItem.alertRuleId == 7">
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">预警说明:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.alertDesc || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">订单号:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.orderId || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">订单类型:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.otherJson.orderType | initDic(orderTypeDic) }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">所属商户:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.companyName || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">商户负责人:</span>
                            <span class="list-item-infoVal">{{ recordItem.compnayUserName || '-' }} {{ recordItem.companyUserPhone || '-' }}</span>
                        </div>
                    </div>
                    <!-- 带柜门订单结单超时预警 -->
                    <div  v-if="recordItem.alertRuleId == 8">
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">预警说明:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.alertDesc || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">订单号:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.orderId || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">订单类型:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.otherJson.orderType | initDic(orderTypeDic) }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">所属商户:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.companyName || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">商户负责人:</span>
                            <span class="list-item-infoVal">{{ recordItem.compnayUserName || '-' }} {{ recordItem.companyUserPhone || '-' }}</span>
                        </div>
                    </div>
                    <!-- 物业缴费预警 -->
                    <div  v-if="recordItem.alertRuleId == 9">
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">预警说明:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.alertDesc || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">物业名称:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.otherJson.name || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">到期日期:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.otherJson.dueDate || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">提醒日期:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.otherJson.alertDate || '-' }}</span>
                        </div>
                    </div>
                    <!-- 电费项目电费占比高预警 -->
                    <div  v-if="recordItem.alertRuleId == 10">
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">预警说明:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.alertDesc || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">电费项目名称:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.otherJson.name || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">电费占比:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.otherJson.rate }}</span>
                        </div>
                    </div>
                    <!-- 电表剩余费用预警 -->
                    <div  v-if="recordItem.alertRuleId == 11">
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">预警说明:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.alertDesc || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">电表号:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.otherJson.meterNumber || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">剩余费用:</span>
                            <span class="list-item-infoVal textOver1">￥{{ (recordItem.otherJson.remainMoney/100).toFixed(2) }}</span>
                        </div>
                    </div>
                    <!-- 每日客诉预警 -->
                    <div  v-if="recordItem.alertRuleId == 12">
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">预警说明:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.alertDesc || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">累计待处理:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.otherJson.totalPendingComplaints || '0' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">累计已超时:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.otherJson.totalOverTimeComplaints || '0' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">所属商户:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.companyName || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">商户负责人:</span>
                            <span class="list-item-infoVal">{{ recordItem.compnayUserName || '-' }} {{ recordItem.companyUserPhone || '-' }}</span>
                        </div>
                    </div>
                    <!-- 烟雾预警 -->
                    <div  v-if="recordItem.alertRuleId == 13">
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">预警说明:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.otherJson.currentRatio || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">设备号:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.deviceCode || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">设备名称:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.mark || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">站点名称:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.stationName || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">站点地址:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.stationAddress || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">所属商户:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.companyName || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">商户负责人:</span>
                            <span class="list-item-infoVal">{{ recordItem.compnayUserName || '-' }} {{ recordItem.companyUserPhone || '-' }}</span>
                        </div>
                    </div>
                    <!-- 充电柜预警 -->
                    <div  v-if="recordItem.alertRuleId == 14">
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">设备号:</span>
                            <span class="list-item-infoVal">{{ recordItem.deviceCode || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">设备名称:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.mark || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">预警说明:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.otherJson.currentRatio || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">站点名称:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.stationName || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">站点地址:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.stationAddress || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">所属商户:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.companyName || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">商户负责人:</span>
                            <span class="list-item-infoVal">{{ recordItem.compnayUserName || '-' }} {{ recordItem.companyUserPhone || '-' }}</span>
                        </div>
                    </div>
                    <!-- 充电柜断电预警 -->
                    <div  v-if="recordItem.alertRuleId == 15">
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">设备号:</span>
                            <span class="list-item-infoVal">{{ recordItem.deviceCode || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">设备名称:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.mark || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">预警说明:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.alertDesc || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">站点名称:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.stationName || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">站点地址:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.stationAddress || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">所属商户:</span>
                            <span class="list-item-infoVal textOver1">{{ recordItem.companyName || '-' }}</span>
                        </div>
                        <div class="a-flex-rfsfs list-item-info">
                            <span class="list-item-infoTitle">商户负责人:</span>
                            <span class="list-item-infoVal">{{ recordItem.compnayUserName || '-' }} {{ recordItem.companyUserPhone || '-' }}</span>
                        </div>
                    </div>
                    <div class="a-flex-rfsfs list-item-info">
                        <span class="list-item-infoTitle">处理人:</span>
                        <span class="list-item-infoVal">{{ recordItem.operateUserName || '-' }}</span>
                    </div>
                    <div class="a-flex-rfsfs list-item-info">
                        <span class="list-item-infoTitle">处理完成时间:</span>
                        <span class="list-item-infoVal">{{ recordItem.operateTime || '-' }}</span>
                    </div>
                </div>
                <div class="a-flex-rfec btnContent a-flex-wrap">
                    <el-button size="mini" @click="handleOperate('showMark')">查看备注</el-button>
                    <el-button size="mini" type="primary" @click="handleOperate('addMark')" v-if="recordItem.state < 3">添加备注</el-button>
                    <el-button size="mini" type="primary" @click="handleOperate('handleByMine')" v-if="!recordItem.operateUserName && !recordItem.operateTime">本人处理</el-button>
                    <el-button size="mini" type="danger" @click="handleOperate('handleCloseElectric')" v-if="!recordItem.chargeDown && recordItem.alertRuleId == 13">立即关电</el-button>
                    <el-button size="mini" type="danger" @click="handleOperate('handleSpray')" v-if="recordItem.alertRuleId == 13">一键喷淋</el-button>
                    <el-button size="mini" type="danger" @click="handleOperate('handleOpenSpray')" v-if="recordItem.alertRuleId == 13">开启喷淋</el-button>
                    <el-button size="mini" type="danger" @click="handleOperate('handleCloseSpray')" v-if="recordItem.alertRuleId == 13">关闭喷淋</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                warningStatusDic: [],
                orderTypeDic: [],
                recordItem: '',
            };
        },
        props:{
            recordInfo: {
                default: ''
            }
        },
        watch:{
            recordInfo:{
				deep: true,
				immediate: true,
				handler (val) {
					let obj = {
						...val,
						otherJson: val.otherJson?JSON.parse(val.otherJson):'',
						step: val.state - 1
					}
					this.recordItem = Object.assign({}, this.recordItem, obj)
				}
			}
        },
        created () {
            this.$getDic('alertRecordStatus','select').then(res=>{ this.warningStatusDic = res; })
            this.$getDic('orderType','select').then(res=>{ this.orderTypeDic = res; })
        },
        methods:{
            handleOperate (type) {
                try {
                    let extentsParams = {}
                if(type == 'handleCloseSpray') {
                    extentsParams = {
                        operateType: 2
                    }
                }else if(type == 'handleSpray') {
                    extentsParams = {
                        operateType: 1
                    }
                }
                this.$emit('handleOperate',{
                    type: type,
                    target: this.recordItem,
                    extentsParams: {
                        ...extentsParams
                    }
                })
                } catch (error) {
                    console.log(error);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .list-item{
        width: 100%;
        height: 460px;
        border: none;
        padding: 5px;
        &>div{
            padding: 10px 15px;
            width: 100%;
            height: 100%;
            border-radius: 4px;
            box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
            position: relative;
        }
        .warning{
            box-shadow: inset 0 2px 12px 0 #F56C6C;
            // border: 1px solid #F56C6C;
        }
        .warningEnd{
            // background: #f5f7fa;
            // border: 1px solid #e4e7ed;
            // box-shadow: none;
        }
        .list-item-title{
            font-size: 16px;
            color: #333333;
            font-weight: 700;
            padding-top: 20px;
            display: inline-block;
        }
        .list-item-info{
            font-size: 14px;
            color: #666666;
            padding-top: 12px;
        }
        .list-item-infoTitle{
            color: #333333;
            min-width: 96px;
            // padding-right: 12px;
        }
        .btnContent{
            position: absolute;
            bottom: 12px;
            right: 15px;
        }
        .step-box{
            padding: 0 114px
        }
        .step-spot{
            width: 8px;
            height: 8px;
            background: #ADB0B3;
            border-radius: 50%
        }
        .step-spot1{
            width: 8px;
            height: 8px;
            background: #007AFF;
            border-radius: 50%
        }
        /deep/ .el-step__icon.is-text{
            border: none
        }
    }
</style>